.faq-container {
    background: rgba(4, 0, 16, 0.442);
    box-shadow: 0 6px 30px rgba(10, 10, 10, 0.133);
    border-top: 1px solid rgba(191, 196, 208, 0.281);
    border-bottom: 1px solid rgba(191, 196, 208, 0.281);
    margin-top: 150px;
    padding-bottom: 50px;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-self: center;
}

.faq-item {
    min-height: 10vh;
    width: 60vw;
    align-self: center;
    flex-direction: row;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between; 
}

.faq-answer {
    align-self: flex-start;
    white-space: pre-line;
    line-height: 25px;
}

.faq {
    margin-top: 15px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid rgba(191, 196, 208, 0.281);
    align-self: center;
    flex-direction: column;
    transition: margin-top 10s ease-in-out;
}

.faq-expand {
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50px;
    border: 2px solid rgb(139, 149, 171);
    color: rgba(255, 255, 255, 0.634);
    background-image: linear-gradient(-45deg, rgba(75, 5, 189, 0.74), rgba(0, 195, 255, 0.74));
}

.faq-expand1 {
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50px;
    border: 2px solid rgb(139, 149, 171);
    color: rgba(255, 255, 255, 0.634);
    background-image: linear-gradient(-45deg, rgba(0, 195, 255, 0.74),rgba(75, 5, 189, 0.74));
}

@media screen and (max-width: 1000px) {

    .faq{
        width: 85vw;
    }

    .faq-item{
        width: 85vw;
    }
    
}