body {
  background: linear-gradient(-45deg, #010516, #260145, #010516);
  background-position: top; 
  background-repeat: no-repeat;
  background-size: cover; 
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.app-container {
  flex-direction: column;
  display: block;
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hero-section {
  height: 100vh; 
  width: 100%;
  background-size: auto; 
}

.solution-div {
  max-width: 60%;
}

@media screen and (max-width: 1000px) {

  .hero-section {
    display: inline-block;
    height: auto;
  }
  
  .solution-div {
    max-width: 100%;
  }
}


.translucent-bg {
  background: rgba(30, 29, 29, 0.93);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15.4px);
  -webkit-backdrop-filter: blur(15.4px);
  border: 1px solid rgba(191, 196, 208, 0.281);
  height: 500px;
  width: 100vw;
}

.gradient {
  background: linear-gradient(-45deg, #010516, #260145, #010B17);
  background-size: 400% 400%;
  animation: gradient-bg 20s ease infinite;
  color: #fff;
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.policy-container {
  margin-top: 150px;
  margin-right: 50px;
  margin-left: 50px;
}

.section {
  color: #e6ebf898;
  text-align: justify;
  font-family: sans-serif;
  line-height: 40px;
}

@media screen and (max-width: 1000px) {

  .policy-container {
    margin-right: 15px;
    margin-left: 15px;
  }
  
}
