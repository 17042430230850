nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
}

.nav-active {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
    background: rgba(0, 0, 11, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15.4px);
    -webkit-backdrop-filter: blur(15.4px);
    border-bottom: 1px solid rgba(191, 196, 208, 0.281);
}

.logo {
    width: 45px;
    height: 45px;
    margin-right: 10px;
}

.logo-wrapper {
    left: 50px;
    top: 10px;
    justify-content: left;
    align-items: center;
    align-content: center;
    display: flex;
    position: absolute;
    flex-direction: row;
    font-family: sans-serif;
}

.download-btn {
    position: absolute;
    right: 30px;
    top: 5px;
    padding: 10px;
}

.download-btn-style {
    background-color: rgb(73, 6, 145);
    border-radius: 50px;
    border: none;
    color: rgb(255, 255, 255);
    font-family: sans-serif;
    font-size: 14px;
    height: 35px;
    width: 130px;
    cursor: pointer;
}

.download-btn-style:hover {
    background-color: rgb(214, 187, 244);
    color: rgb(58, 4, 116);
}

.list {
    list-style-type: none;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.items {
    margin-right: 80px;
    font-size: 14px;
    color: #e6ebf898;
    cursor: pointer;
    font-weight: lighter;
    font-family: sans-serif;
}

.items:hover {
    color: #fff;
}

.item-selected {
    margin-right: 80px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    font-weight: lighter;
    font-family: sans-serif;
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 10px;
}

@media screen and (max-width: 1000px) {

    .logo-wrapper {
        left: 10px;
        top: 10px;
        justify-content: left;
        position: absolute;
    }

    .list {
        flex-direction: column;
        height: auto;
        width: 81vw;
        margin-top: 46px;
        margin-left: 20px;
        background: rgb(3, 0, 12);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgb(0, 0, 0);
        border: 0.5px solid rgba(201, 213, 245, 0.707);
    }

    .items {
        width: 100%;
        text-align: center;
        margin-right: 35px;
        color: #e6ebf898;
        padding: 30px 0px;
        font-size: 14px;
        font-weight: bold;
        font-family: sans-serif;
        cursor: pointer;
    }

    .items:hover {
        color: #fff;
    }
    
    .list-item {
        width: 80%;
        border-top: 0.5px solid rgba(201, 213, 245, 0.707);

    }

    .item-selected {
        width: 100%;
        text-align: center;
        margin-right: 35px;
        color: #fff;
        font-weight: bold;
        padding: 30px 0px;
        font-size: 14px;
        font-family: sans-serif;
    }

    .btn {
        display: block;
    }
}