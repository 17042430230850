.hero-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
}

h1 {
    font-size: 70px;
    text-align: start;
}

.heading-line {
    line-height: 1;
    display: block;
    position: relative;
    text-align: start;
}

.heading-line::before {
    /* line-height: 1;
    display: block;
    position: absolute;
    color: #fff;
    pointer-events: none;
    width: 100%; */
}

.heading-line-gradient {
    -webkit-text-fill-color: transparent;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

@keyframes first_heading_gradient {

    0%,
    16.667% {
        opacity: 1;
    }

    33.333%,
    83.333% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes first_heading_white_text {

    0%,
    16.667% {
        opacity: 0;
    }

    25%,
    91.667% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes second_heading_gradient {

    0%,
    16.667% {
        opacity: 0;
    }

    33.333%,
    50% {
        opacity: 1;
    }

    66.667%,
    100% {
        opacity: 0;
    }
}

@keyframes second_heading_white_text {

    0%,
    25% {
        opacity: 1;
    }

    33.333%,
    50% {
        opacity: 0;
    }

    58.333%,
    100% {
        opacity: 1;
    }
}

@keyframes third_heading_gradient {

    0%,
    50% {
        opacity: 0;
    }

    66.667%,
    83.333% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes third_heading_white_text {

    0%,
    58.333% {
        opacity: 1;
    }

    66.667%,
    83.333% {
        opacity: 0;
    }

    91.667%,
    100% {
        opacity: 1;
    }
}

.heading-line-first {
    background : linear-gradient(to left, #ed7336, #ec0565);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* animation: first_heading_gradient 5s infinite; */
}

.heading-line-first::before {
    /* content: "Learn"; */
    /* animation: first_heading_white_text 8s infinite; */
}

.heading-line-second {
    background: linear-gradient(to left, #d2a140, #ffee00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* animation: second_heading_gradient 8s infinite; */
}

.heading-line-second::before {
    /* content: "Practice"; */
    /* animation: second_heading_white_text 8s infinite; */
}

.heading-line-third {
    background: linear-gradient(to left, #8eec4b, #2be879);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* animation: third_heading_gradient 11s infinite; */
}

.heading-line-third::before {
    /* content: "Succeed"; */
    /* animation: third_heading_white_text 8s infinite; */
}
p {
    color: #e6ebf898;
    max-width: 60vw;
}

.image {
    width: 40vw;
    height: 110vh;
    margin-right: 20px;
    margin-top: 50px;
}

.left-description {
    margin-top: 170px;
    margin-left: 20px;
}

.right-description {
    margin-right: 70px;
    align-self: center;
    text-align: justify;
}

.button-style {
    background-color: #490691;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    font-family: sans-serif;
    font-size: 14px;
    height: 50px;
    min-width: 160px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.button-style:hover {
    background-color: rgb(214, 187, 244);
    color: rgb(58, 4, 116);
}

.google-play {
    height: 55px;  
    width: 170px;
    cursor: pointer;
}
a {
    text-decoration: none;
}

@media screen and (max-width: 1000px) {

    .hero-container {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 100px;
    }

    h1 {
        font-size: 60px;
    }

    p {
        max-width: 100vw;
    }

    .left-description {
        margin-top: 0px;
        margin-left: 0px;
    }

    .right-description {
        margin-right: 0px;
    }

    .image {
        width: 90vw;
        height: 60vh;
        margin-top: 40px;
    } 
}
