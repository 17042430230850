.partners-container{
    margin-top: 40px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.partners {
    flex-direction: row;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    align-self: center;
    display: flex;
}

.partner-logo {
    height: 110px;
    width: 250px;
    margin-right: 35px;
    border-radius: 15px;
}

@media screen and (max-width: 1000px)  {
    .partners {
        flex-direction: column;
    }

    .partner-logo {
        margin-top: 15px;
    }
}
