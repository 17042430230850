
.container {
    margin-top: 100px;
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.feature-h1 {
    font-size: 50px;
    display: flex;
    align-self: center;
    text-align: start;
}

.feature-description {
    color: #e6ebf898;
    max-width: 50vw;
    align-self: center;
    text-align: center;
}

.feature-container {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    align-self: center;
    display: flex;
}

.feature {
    background: rgba(4, 0, 16, 0.442);
    border-radius: 16px;
    box-shadow: 0 6px 30px rgba(10, 10, 10, 0.133);
    border: 1px solid rgba(191, 196, 208, 0.281);
    height: 160px;
    width: 250px;
    padding: 20px;
    margin-right: 30px;
    margin-left: 15px;
    text-align: justify;
}

.wrap-title {
    flex-direction: row;
    align-items: start;
    justify-content: start;
    display: flex;
}

.feature-num {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 15px;
    border-radius: 50px;
    border: 2px solid rgb(139, 149, 171);
    color: rgba(255, 255, 255, 0.634);
    background-image: linear-gradient(-45deg, rgba(255, 0, 68, 0.74), rgba(19, 0, 128, 0.74));
}

.feature-desc {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    display: flex;
    margin-right: 15px;
    border-radius: 50px;
    border: 2px solid rgb(139, 149, 171);
    color: rgba(255, 255, 255, 0.634);
    background-image: linear-gradient(-45deg, rgba(255, 225, 0, 0.999), rgba(200, 75, 3, 0.9));
}

.feature-title {
    font-size: 35;
    font-weight: 600;
    color: #fff;
}

.image-text-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.image-text-container {
    flex-direction: row;
    margin-top: 100px;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
}

.image-text-container1 {
    flex-direction: row;
    margin-top: 80px;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
}

.image-style1 {
    width: 18vw;
    height: 555px;
    margin-right: 100px;
}

.image-style2 {
    width: 18vw;
    height: 540px;
    margin-left: 150px;
}

.image-text {
    color: #e6ebf898;
    max-width: 35vw;
    text-align: justify;
}

.text-wrapper {
    align-self: flex-start;
    margin-top: 50px;
    max-width: 40vw;
}

@media screen and (max-width: 1000px) {

    .text-wrapper {
        max-width: fit-content;
    }

    .feature-h1 {
        text-align: center;
    }

    .container {
        margin-top: 300px;
    }

    .feature-h1 {
        font-size: 40px;
    }

    .feature-description {
        max-width: 90vw;
    }

    .feature-container {
        flex-direction: column;
        display: grid;
        margin-bottom: 50px;
        width: 100%;
        margin-left: 70px;
    }

    .feature {
        margin-bottom: 20px;
        margin-top: 10px;
        width: 280px;
    }

    .image-text-container {
        flex-direction: column;
        margin-left: 25px;
        margin-right: 25px;
    }

    .image-text-container1 {
        flex-direction: column-reverse;
        margin-left: 25px;
        margin-right: 25px;
    }

    .image-style1 {
        width: 65vw;
        height: 57vh;
        margin-right: 0px;
        margin-top: 240px;
    }

    .image-style2 {
        width: 62vw;
        height: 57vh;
        margin-left: 0px;
        margin-top: 50px;
    }

    .image-text {
        max-width: 100vw;
    }
    
}