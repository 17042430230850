
.banner-container {
    margin-top: 150PX;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    display: flex;
}

.banner-bg {
    background-image: url("../../assets/images/banner.png");
    background-repeat: no-repeat;
    background-size: cover; 
    border-radius: 30PX;
    border: 2px solid rgba(191, 196, 208, 0.281);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    height: 400px;
    width: 80%;
    align-self: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    display: flex;
    margin-bottom: 120px;
}

.banner-h2 {
    font-size: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.footer-container {
    width: 100vw;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    margin-bottom: 50px;
}

.socail-wrapper {
    flex-direction: row;
    justify-content: center;
    align-content: center;
    display: flex;
}

.policy-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    display: flex;
}

.p {
    margin-right: 45px;
}

.copy-right{
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    margin-bottom: 50px;
}

.facebook-icon {
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    align-self: center;
    display: flex;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(255, 255, 255);
    background-image: linear-gradient(-45deg, rgba(7, 3, 97, 0.999), rgba(29, 18, 236, 0.9));
}

.twitter-icon {
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    align-self: center;
    display: flex;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(255, 255, 255);
    background-image: linear-gradient(-45deg, rgba(7, 51, 183, 0.999), rgba(18, 123, 236, 0.9));
}

@media screen and (max-width: 1000px) {

    .banner-bg {
        width: 90vw;
    }

    .policy-wrapper {
        flex-direction: column;
        margin-top: 15px;
        text-align: center;
        align-items: center;
        display: flex;
    }

    .p {
        margin-right: 0px;
    }

    .footer-container {
        flex-direction: column;
    }
    
}